import React from "react";
import Header from "../Header/Header";
import "./payment.css";
import CardImg from "../../assets/card-img.png";
import check from "../../assets/check.png";
import checktwo from "../../assets/checktwo.png";
const Payment = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <p className="personal-para">
              Personal Information
              <span className="payment-para-two"> Payment</span>
            </p>
            <img id="checktwo" className="checktwo" src={checktwo} />
            <input
              className="first-name-input"
              type=" password"
              placeholder="First Name"
            />
            <input
              className="last-name-input"
              type=" password"
              placeholder="Last Name"
            />
            <br></br>
            <input
              className="input-payment-three"
              type=" emai;"
              placeholder="Email Address"
            />
            <br></br>
            <input
              className="input-payment-three"
              type=" emai;"
              placeholder="Phone Number"
            />
            <a href="/personal">
              <button className="btn-payment">Next</button>
            </a>
          </div>
          <div className="col-md-6 payment-bg">
            <div class="cards">
              <div class="container con">
                <p className="card-headerss">Essential</p>
                <p className="card-par">
                  {" "}
                  <span className="card-nums">$59</span>/month
                </p>
                <img className="card-imgs" src={CardImg} />

                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    የአርፋ ማፍጠሪያ ገቢ ማሰባሰቢያ ዝግጅት
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 2000 $
                  </li>{" "}
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="bitapps-container">
        <p className="bitapps-para">
          © 2023 | Designed and developed by
          <a className="bitapps-links" href="http://bitappstech.com/">
            {" "}
            <span className="bitapps-span">bitappstech</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Payment;
