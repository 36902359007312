import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import cardswiperImg from "../../assets/card-img.png";
import check from "../../assets/check.png";
import checktwo from "../../assets/checktwo.png";
import "swiper/css";
import "swiper/css/pagination";
import "./swiper.css";
import { Pagination } from "swiper";
import cover from "../../assets/logincover.jpg";
import BasicImg from "../../assets/basic.png";
import essential from "../../assets/ess.png";
import Header from "../Header/Header";
export default function App() {
  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => {
    if (swiper) swiper.slideTo(index);
  };

  return (
    <div className="main-swiper">
      <Swiper
        centeredSlides={true}
        onSwiper={setSwiper}
        slidesPerView={3}
        spaceBetween={0}
        pagination={{
          loop: true,
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          {" "}
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Basic</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$19</span>/month
              </p>
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={BasicImg} />

                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    የረመዳን ማፍጠሪያ ገቢ ማሰባሰቢያዝግጅት
                  </li>{" "}
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 1340 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                  <button className="basic-btn">
                    <p className="basic-btn-para">Change Plan</p>
                  </button>
                </ul>
              </a>
            </div>
          </div>{" "}
        </SwiperSlide>
        <SwiperSlide>
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Essential</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$59</span>/month
              </p>
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={essential} />

                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    የአርፋ ማፍጠሪያ ገቢ ማሰባሰቢያ ዝግጅት
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 2000 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                </ul>
                <button className="ess-btn">
                  <p className="ess-btn-para">Stop Plan</p>
                </button>
              </a>
            </div>
          </div>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Premium</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$119</span>/month
              </p>{" "}
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={cardswiperImg} />
                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    ቤት ለሌላቸው የዕለት ተዕለት ቁርስ
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 2500 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                </ul>
                <button className="basic-btn">
                  <p className="basic-btn-para">Change Plan</p>
                </button>{" "}
              </a>
            </div>
          </div>{" "}
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Dimond</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$200</span>/month
              </p>
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={BasicImg} />

                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    ኣቅም ለሌላቸው ተማሪዎች ምግብ
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 3000 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                  <button className="basic-btn">
                    <p className="basic-btn-para">Change Plan</p>
                  </button>{" "}
                </ul>
              </a>
            </div>
          </div>{" "}
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
