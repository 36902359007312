import React, { useState } from "react";
import "./accordion.css";
import { AiOutlineDown } from "react-icons/ai";
import { RiArrowDropUpLine } from "react-icons/ri";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="">{title}</div>
        <div className="plus-sign">
          {isActive ? <AiOutlineDown /> : <RiArrowDropUpLine />}
        </div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
