import { useState } from "react";
import React from "react";

import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import "./form.css";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
export default function Form() {
  // States for registration
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Phonenumber, setPhonenumber] = useState("");
  const [Confirmpassword, setConfirmpassword] = useState("");
  // new
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  // Handling the name change
  const handleName = (e) => {
    setName(e.target.value);
    setSubmitted(false);
  };

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };
  // Handling the email change
  const handleNumber = (e) => {
    setPhonenumber(e.target.value);
    setSubmitted(false);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setSubmitted(false);
  };
  // Handling the password change
  const handleConfirmpassword = (e) => {
    setConfirmpassword(e.target.value);
    setSubmitted(false);
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      Phonenumber === "" ||
      Confirmpassword === ""
    ) {
      setError(true);
    } else {
      setSubmitted(true);
      setError(false);
    }
  };

  // Showing success message
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChanges = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h1 className="error-msg-two">User {name} successfully registered!!</h1>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none",
        }}
      >
        <h1 className="error-msg">Please enter all the fields</h1>
      </div>
    );
  };

  return (
    <div className="form" id="form-id">
      <div>
        <h1 className="sing-up-main-header">Sing Up</h1>
      </div>

      {/* Calling to the methods */}
      <div className="messages">
        {errorMessage()}
        {successMessage()}
      </div>

      <form>
        {/* Labels and inputs for form data */}
        <label className="label"></label>

        <input
          onChange={handleEmail}
          className="input"
          value={email}
          type="email"
          placeholder="Enter email"
        />

        <label className="label"></label>
        <input
          onChange={handleName}
          className="input"
          value={name}
          type="text"
          placeholder="Create User Name"
        />

        <label className="label"></label>
        <input
          onChange={handleNumber}
          className="input"
          value={Phonenumber}
          type="number"
          placeholder="Phone Number"
        />
        <label className="label"></label>
        <input
          onChange={handlePassword}
          className="input"
          value={password}
          type="password"
          placeholder="Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <label className="label"></label>
        <input
          onChange={handleConfirmpassword}
          className="input"
          value={Confirmpassword}
          type=" password"
          placeholder="Confirm password"
        />

        <br></br>
        <button
          id="sign-up-btn"
          onClick={handleSubmit}
          className="btn"
          type="submit"
        >
          <p className="Register-para">Register</p>
        </button>
        <br></br>
        {/* <button id="sign-up-btn">Register</button> */}
        {/* <InputLabel htmlFor="standard-adornment-password">
          Enter your Password
        </InputLabel>
        <Input
          type={values.showPassword ? "text" : "password"}
          onChange={handlePasswordChange("password")}
          value={values.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        /> */}
      </form>
    </div>
  );
}
