import React from "react";
import "./NavTwo.css";
import log from "../../assets/log.png";

const NavTwo = () => {
  return (
    <div>
      <div className="container header-con-two">
        <img src={log} alt="" />
        <div className="two-btns">
          <a href="/signin">
            <button id="nav-signs">
              <p className="nav-sign-home">Log in</p>
            </button>
          </a>
          <a href="/login">
            <button className="nav-sign-two">
              <p className="nav-sign-home-two">Sign up</p>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavTwo;
