import React from "react";
import logincover from "../../assets/logincover.jpg";
import Form from "./form";
import "./login.css";
import { useState } from "react";
import SignIn from "../SignIn/SignIn";
import FormSignin from "../SignIn/FormSignin";

const Login = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-6 back-login"
            style={{ backgroundImage: `url(${logincover})` }}
          >
            <div className="singup-all-text">
              <p className="signup-paragraph">Sign Up </p>
              <p className="singup-sub">A help to those who need it </p>
              <p className="singup-sub-two">
              Register here and enter account detail for monthly plan
              </p>
              <p className="singup-sub-two">
                if you have already an account
                <a className="sign-in-links" href="./signin">
                  <span className="sign-link"> sign in here</span>
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <Form />
            {/* <FormSignin /> */}
          </div>
        </div>
      </div>
      <div className="container-fluid" id="bitapps-container">
        <p className="bitapps-para">
          © 2023 | Designed and developed by
          <a className="bitapps-links" href="http://bitappstech.com/">
            {" "}
            <span className="bitapps-span">bitappstech</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
