import React from "react";
import "./home.css";
import Accordion from "./Accordion";
import { accordionData } from "./content";
import { accordionDataTwo } from "./content";
import footerLog from "../../assets/log.png";
import Swipers from "../Swiper/Swiper";
import Mobile from "../Mobile/Mobile";

import star from "../../assets/star.png";
import NavTwo from "../NavTwo/NavTwo";
const Home = () => {
  return (
    <div>
      <NavTwo />
      <div className="container-fluid home-main-bg">
        <div className="container">
          <p className="home-header">
          Each donation is an essential <br/> 
          help for everyone's life
          </p>
          <p className="home-paragraph">
          The ways to help others in the best possible way
          </p>
        </div>
        <button className="pop-btn">
          <p className="pop-para">Choose Plan </p>{" "}
          <img className="start-img" src={star} />
        </button>
      </div>
      <div className="container-fluid" id="swiper-home">
        <Swipers />
      </div>
      <div className="container" id="mobile-home">
        <Mobile />
      </div>
      <div className="container">
        <div className="row">
          <p className="frequently-ask-header">Frequently asked questions</p>
          <p className="frequently-ask-paragraph">
            Can’t find the anwser you’re looking for ? Reach out to customer
            support team.
          </p>
          <div className="col-md-6">
            <div className="accordion">
              {accordionData.map(({ title, content }) => (
                <Accordion title={title} content={content} />
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="accordion">
              {accordionDataTwo.map(({ title, content }) => (
                <Accordion title={title} content={content} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="container home-main-bg-two">
          <h1 className="donation-header">
            Each donation is an essential help which improves everyone's life
          </h1>
          <p className="donation-paragraph">
          A help to those who need it
          </p>
        </div>
      </div>
      <div className="container">
        <img className="footer-img" src={footerLog} />
        {/* <input type="email" id="email" placeholder="Enter email" name="email" />
        <input
          type="password"
          id="pwd"
          placeholder="Enter password"
          name="pswd"
        /> */}
      </div>
      <div className="container-fluid" id="bitapps-container">
        <p className="bitapps-para">
          © 2023 | Designed and developed by
          <a className="bitapps-links" href="http://bitappstech.com/">
            {" "}
            <span className="bitapps-span">bitappstech</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Home;
