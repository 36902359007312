import React from "react";
import { useState } from "react";
import "./form.css";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

const FormSignin = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div>
      {" "}
      <form>
        <input
          id="form-inp-name"
          type={"email"}
          placeholder="Enter email "
          required
        />
        <input
          id="form-inp"
          type={passwordType}
          onChange={handlePasswordChange}
          value={passwordInput}
          name="password"
          class="form-control"
          placeholder="Password"
          required
        />
        <div className="input-group-btn">
          <button id="btn-form" className="btn " onClick={togglePassword}>
            {passwordType === "password" ? (
              <AiFillEyeInvisible />
            ) : (
              <AiFillEye />
            )}
          </button>
          <a href="./">
            {" "}
            <button id="sign-in-btns" className="btn" type="submit">
              <p className="sign-in-para">Login</p>
            </button>
          </a>
        </div>
      </form>
    </div>
  );
};

export default FormSignin;
