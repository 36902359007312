import React from "react";
import "./header.css";
import Profile from "../../assets/profile.png";
import log from "../../assets/log.png";
const Header = () => {
  return (
    <div>
      <div className="container header-con">
        <a href="./">
          {" "}
          <img src={log} alt="" />
        </a>
        <img className="head-img" src={Profile} />
      </div>
    </div>
  );
};

export default Header;
