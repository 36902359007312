import React from "react";
import "./personal.css";
import Header from "../Header/Header";
import CardImg from "../../assets/card-img.png";
import check from "../../assets/check.png";
import checktwo from "../../assets/checktwo.png";
import Paypal from "../../assets/PayPal.png";
import Visa from "../../assets/visa.png";
import Discover from "../../assets/Discover.png";
import Maestro from "../../assets/Maestro.png";
import Mastercard from "../../assets/Mastercard.png";
import { BiArrowBack } from "react-icons/bi";
const Personal = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <p className="personal-para">
              Personal Information
              <span className="payment-para-two"> Payment</span>
            </p>
            <img className="checktwo" src={checktwo} />

            <button className="input-payment">
              <p className="pal-paras">Pay with Paypal</p>
            </button>
            <img className="paypal-img" src={Paypal} />
            <img className="dis-img" src={Discover} />
            <img className="mas-img" src={Maestro} />
            <img className="master-img" src={Mastercard} />

            <button className="input-payment-two">
              <p className="pay-par">Pay with Credit</p>
            </button>
            <img className="visa-img" src={Visa} />
            <br></br>
            <input
              className="input-payment-three"
              type=" emai;"
              placeholder="Email Address"
            />
            <br></br>
            <input
              className="input-payment-three"
              type=" emai;"
              placeholder="Phone Number"
            />
            <p></p>
            <a href="/payment">
              {" "}
              <span className="para-back">Back</span>
            </a>
            <button className="btn-payment">Pay</button>
          </div>
          <div className="col-md-6 payment-bg">
            <div class="cards">
              <div class="container con">
                <p className="card-headerss">Essential</p>
                <p className="card-par">
                  {" "}
                  <span className="card-nums">$59</span>/month
                </p>
                <img className="card-imgs" src={CardImg} />

                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    የአርፋ ማፍጠሪያ ገቢ ማሰባሰቢያ ዝግጅት
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 2000 $
                  </li>{" "}
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="bitapps-container">
        <p className="bitapps-para">
          © 2023 | Designed and developed by
          <a className="bitapps-links" href="http://bitappstech.com/">
            {" "}
            <span className="bitapps-span">bitappstech</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Personal;
