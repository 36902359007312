import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import BasicImg from "../../assets/basic.png";
import essential from "../../assets/ess.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./mobile.css";
// import required modules
import { Pagination } from "swiper";
import cardswiperImg from "../../assets/card-img.png";
import check from "../../assets/check.png";
import checktwo from "../../assets/checktwo.png";
export default function App() {
  return (
    <div className="mobile-mains">
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
      <SwiperSlide>
          {" "}
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Basic</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$19</span>/month
              </p>
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={BasicImg} />
                {/* <h4>
                  <b>John Doe</b>
                </h4>
                <p>Architect & Engineer</p> */}
                <ul>
                 
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    የረመዳን ማፍጠሪያ ገቢ 
                  </li>{" "}
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 1340 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                  
                  <button className="basic-btn">
                    <p className="basic-btn-para">Change Plan</p>
                  </button>
                </ul>
              </a>
            </div>
          </div>{" "}
        </SwiperSlide>
        <SwiperSlide>
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Essential</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$59</span>/month
              </p>
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={essential} />
                {/* <h4>
                  <b>John Doe</b>
                </h4>
                <p>Architect & Engineer</p> */}
                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
               የአርፋ ማፍጠሪያ ገቢ 
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 2000 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                </ul>
                <button className="ess-btn">
                  <p className="ess-btn-para">Stop Plan</p>
                </button>
              </a>
            </div>
          </div>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Premium</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$119</span>/month
              </p>{" "}
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={cardswiperImg} />
                {/* <h4>
                  <b>John Doe</b>
                </h4>
                <p>Architect & Engineer</p> */}
                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    ቤት ለሌላቸው የዕለት ቁርስ
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 2500 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                </ul>
                <button className="basic-btn">
                  <p className="basic-btn-para">Change Plan</p>
                </button>{" "}
              </a>
            </div>
          </div>{" "}
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div class="cardswipers">
            <div class="container con">
              <p className="cardswiper-headerss">Dimond</p>
              <p className="cardswiper-par">
                {" "}
                <span className="cardswiper-nums">$200</span>/month
              </p>
              <a className="swipers-links" href="/payment">
                <img className="cardswiper-imgs" src={BasicImg} />
                {/* <h4>
                  <b>John Doe</b>
                </h4>
                <p>Architect & Engineer</p> */}
                <ul>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    ኣቅም ለሌላቸው ተማሪዎች ምግብ
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    GOAL : 3000 $
                  </li>
                  <li className="list-para">
                    <img className="check-img" src={check} />
                    RAISED : 0 $
                  </li>
                  <button className="basic-btn">
                    <p className="basic-btn-para">Change Plan</p>
                  </button>{" "}
                </ul>
              </a>
            </div>
          </div>{" "}
        </SwiperSlide>
       
       
    
      
       
      </Swiper>
      <div className="container-fluid" id="bitapps-container">
        <p className="bitapps-para">
          © 2023 | Designed and developed by
          <a className="bitapps-links" href="http://bitappstech.com/">
            {" "}
            <span className="bitapps-span">bitappstech</span>
          </a>
        </p>
      </div>
    </div>
  );
}
