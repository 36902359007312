import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
// import Mobile from "./components/Mobile/Mobile";
// import Header from "./components/Header/Header";
import Payment from "./components/Payment/Payment";
import Personal from "./components/Personal/Personal";
import Swiper from "./components/Swiper/Swiper";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signin" element={<SignIn />}></Route>
        <Route path="/payment" element={<Payment />}></Route>

        <Route path="/personal" element={<Personal />}></Route>
      </Routes>
    </div>
  );
}

export default App;
