import React from "react";
import "./signin.css";
import Signinimg from "../../assets/Signin.png";
import FormSignin from "./FormSignin";
const SignIn = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-6 back-signin"
            style={{ backgroundImage: `url(${Signinimg})` }}
          >
            <div className="signin-all-text">
              <p className="signin-sub">A help to those who need it </p>

              <p className="signin-sub-two">
                Login here to apply monthly plan <br /> <br /> or
                <a href="./login" className="link-signin">
                  <span className="sign-link"> Register here</span>
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <FormSignin />
          </div>
        </div>
      </div>
      <div className="container-fluid" id="bitapps-container">
        <p className="bitapps-para">
          © 2023 | Designed and developed by
          <a className="bitapps-links" href="http://bitappstech.com/">
            {" "}
            <span className="bitapps-span">bitappstech</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
